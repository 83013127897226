import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Grid, Icon, Typography } from '@popmenu/common-ui';

import { createEvent } from '~/utils/eventable';
import { sortByKey } from '../../utils/arrays';
import { trackFacebookEvent } from '../../utils/fbq';
import { appendConversionScripts } from '../../utils/conversion';
import { notBlankValidator } from '../../utils/forms';
import { openTermsModal } from '../ModalActions';
import { withIntl } from '../../utils/withIntl';
import { withStyles } from '../../utils/withStyles';
import SignUpFormStyles from '../VipV2Modal/styles';
import createUserMutation from '../../libs/gql/mutations/users/createUserMutation.gql';
import createConsumerUserMutation from '../../libs/gql/mutations/users/createConsumerUserMutation.gql';
import restaurantLocationsQuery from '../../libs/gql/queries/locations/restaurantWithLocationNamesQuery.gql';

import Query from '../Query';
import BasicForm, { SelectGroup, SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import FollowDisclaimer from './FollowDisclaimer';
import MarketingOptInRequest from './MarketingOptInRequest';
import OfferTag from '../../assets/svg/popmenu_guest_profile_offer_tag.svg';
import VIPSVG from '../../assets/svg/vip2.svg';
import Loading from '../Loading';
import { validateMonthDate, monthDayOptions, validateSubmit } from './utils';
import { AH, AHLevelProvider } from '../../consumer/shared/AccessibleHeading';
import { useFeatureFlags } from '../../utils/featureFlagsContext';
import { setVipData } from '../ModalActions';
import { TermsModalType } from '../../utils/types';
import { updateAriaAttributes } from './accessibilityUtils';

const SignUpV2Form = (props) => {
  const { classes, restaurant, setFavoriteLocationId, setNewUserName, setNewUserPhone, setUseValidationAccessCode, t, onExistingUser, userEmail, isWaitlistPath } = props;
  const { isFeatureActive } = useFeatureFlags();
  const dispatch = useDispatch();
  const isEmailOptInEnabled = isFeatureActive('isEmailOptInEnabled');
  const isSmsOptInEnabled = isFeatureActive('isSmsOptInEnabled');
  const isNcrLoyaltyEnabled = isFeatureActive('isNcrLoyaltyEnabled');
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [isDayDropdownOpen, setIsDayDropdownOpen] = useState(false);

  const [email, setEmail] = useState(userEmail);
  const { customConversionScripts, googleAdsConversionActionScripts, id, isMarketingOptInEnabled, offerMessageCampaignCount, signUpHeadingCta, signUpSetting, signUpSubheadingCta } = restaurant;

  const isOnOloPage = useSelector(state => state.modals.isOnOloPage);
  // did the user submit their email through the become a vip follow prompt (FollowPromptV2.jsx)
  const didSubmitEmailWithFollowPrompt = useSelector(state => state.modals.didSubmitEmailWithFollowPrompt);

  // this useEffect is strictly for accessibility updates
  useEffect(() => {
    updateAriaAttributes(t('models.user.month'), isMonthDropdownOpen);
  }, [isMonthDropdownOpen, t]);

  useEffect(() => {
    updateAriaAttributes(t('models.user.day'), isDayDropdownOpen);
  }, [isDayDropdownOpen, t]);

  useEffect(() => {
    if (isOnOloPage && offerMessageCampaignCount > 0) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | not_follower | offer | sign_in_modal',
        eventType: 'not_follower_profile_sign_in_offer_modal',
      });
    } else if (isOnOloPage) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | not_follower | no_offer | sign_in_modal',
        eventType: 'not_follower_profile_sign_in_no_offer_modal',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Query
      query={restaurantLocationsQuery}
      variables={{
        restaurantId: id,
      }}
    >
      {({ data, loading }) => {
        if (loading || !data || !data.restaurant.locations) {
          return <Loading size="lg" />;
        }

        return (
          <BasicForm
            defaultValues={{
              anniversaryMonth: null,
              birthMonth: null,
              email: userEmail,
            }}
            mutate={{
              mutation: isNcrLoyaltyEnabled ? createConsumerUserMutation : createUserMutation,
              onCompleted: (values) => {
                if ((values.createUser && values.createUser.user) || (values.createConsumerUser && values.createConsumerUser.id)) {
                  appendConversionScripts({ customScripts: customConversionScripts, googleAdsConversionActionScripts });
                  trackFacebookEvent('CompleteRegistration');
                  setNewUserName(values.createUser?.user?.firstName || values.createConsumerUser?.displayName);
                  setUseValidationAccessCode(true);
                  if (values.createConsumerUser) {
                    dispatch(setVipData({ userEmail: email }));
                  }
                } else {
                  onExistingUser(email);
                }
              },
              toVariables: ({ subscribeSms, subscribeEmail, ...variables }) => {
                const nameArray = variables.name && variables.name.split(' ');
                const userInputObj = {
                  ...variables,
                  anniversaryDay: variables.anniversaryDay ? parseInt(variables.anniversaryDay, 10) : undefined,
                  anniversaryMonth: variables.anniversaryDay ? parseInt(variables.anniversaryMonth, 10) : undefined,
                  birthDay: variables.birthDay ? parseInt(variables.birthDay, 10) : undefined,
                  birthMonth: variables.birthDay ? parseInt(variables.birthMonth, 10) : undefined,
                  firstName: nameArray ? (nameArray.length >= 2 ? nameArray.slice(0, nameArray.length - 1).join(' ') : variables.name) : undefined,
                  lastName: variables.name ? (nameArray.length >= 2 ? nameArray[nameArray.length - 1] : '') : undefined,
                  name: undefined,
                  restaurantId: id,
                  skipValidation: true,
                  userEntryPoint: isWaitlistPath ? 0 : null,
                  weddingDay: variables.weddingDay ? parseInt(variables.weddingDay, 10) : undefined,
                  weddingMonth: variables.weddingMonth ? parseInt(variables.weddingMonth, 10) : undefined,
                };

                if (isEmailOptInEnabled) {
                  userInputObj.isUnsubscribed = !subscribeEmail;
                } else {
                  userInputObj.isUnsubscribed = false;
                }

                if (isSmsOptInEnabled) {
                  userInputObj.isSmsUnsubscribed = !subscribeSms;
                } else {
                  userInputObj.isSmsUnsubscribed = false;
                }

                return ({
                  userInput: userInputObj,
                });
              },
            }}
            onSubmit={(values) => {
              createEvent({
                eventableType: 'BecomeVipV2',
                eventType: 'v2_sign_up_button',
              });
              setFavoriteLocationId(values.favoriteLocationId);
            }}
            data-cy="sign_up_v2_form"
          >
            {(basicFormApi) => {
              const directedFromPrompt = !!userEmail;
              const heading = signUpHeadingCta || (directedFromPrompt ? t('sessions.sign_up_v2_header') : t('sessions.become_a_vip_header'));
              const subheading = signUpSubheadingCta || (directedFromPrompt ? t('sessions.sign_up_v2_subheader') : t('sessions.become_a_vip_subheader'));
              const enabledLocations = data.restaurant.locations.filter(location => location.isLocationEnabled);
              const { values } = basicFormApi;

              return (
                <div className={classes.verifiedPageContainer}>
                  {(isOnOloPage || (didSubmitEmailWithFollowPrompt && restaurant.activeBecomeVipMarketingOffer)) && offerMessageCampaignCount > 0 ? (
                    <React.Fragment>
                      <OfferTag width={60} height="100%" />
                      <AH typography className={classes.headerTertiary} variant="h3" align="center" id="become-vip-title" aria-live="polite" role="status">
                        <FormattedMessage
                          id="sessions.with_offers.sign_up"
                          defaultMessage="You have {offerMessageCampaignCount} available {offer}!"
                          values={{ offer: offerMessageCampaignCount === 1 ? 'offer' : 'offers', offerMessageCampaignCount }}
                        />
                      </AH>
                      <AHLevelProvider>
                        <AH
                          typography
                          align="center"
                          className={classes.subheader}
                          variant="h5"
                          id="become-vip-description"
                          aria-live="polite"
                          role="status"
                        >
                          <FormattedMessage
                            id="sessions.with_offers.sign_up_v2_subtitle"
                            defaultMessage="Sign up to Become a VIP and apply {offer}"
                            values={{ offer: offerMessageCampaignCount === 1 ? 'this offer to your order' : 'one of them to your order' }}
                          />
                        </AH>
                      </AHLevelProvider>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Icon icon={VIPSVG} className={classes.signUpIcon} />
                      <AH typography className={classes.header} component="h3" variant="h4" align="center" id="become-vip-title" aria-live="polite" role="status">
                        {heading}
                      </AH>
                      <br />
                      <Typography className={classes.subheader} align="center" id="become-vip-description" aria-live="polite" role="status">
                        {subheading}
                      </Typography>
                    </React.Fragment>
                  )}
                  <AHLevelProvider>
                    <TextFieldGroup
                      id="pm-signup-email-input"
                      inputProps={{ 'aria-required': 'true' }}
                      field="email"
                      title={t('models.user.email')}
                      validate={notBlankValidator}
                      variant="outlined"
                      onInput={e => setEmail(e.target.value)}
                    />
                    {signUpSetting.isSignUpNameEnabled && (
                      <TextFieldGroup
                        id="pm-signup-name-input"
                        inputProps={{ 'aria-required': 'true' }}
                        field="name"
                        title={t('models.user.full_name')}
                        validate={signUpSetting.isSignUpNameRequired ? notBlankValidator : undefined}
                        variant="outlined"
                      />
                    )}
                    {(signUpSetting.isSignUpPhoneEnabled || isSmsOptInEnabled) && (
                      <TextFieldGroup
                        id="pm-signup-phone-input"
                        field="phone"
                        title={isNcrLoyaltyEnabled ? t('users.phone') : t('users.phone_optional')}
                        type="tel"
                        validate={isNcrLoyaltyEnabled ? notBlankValidator : undefined}
                        variant="outlined"
                        onTextChange={e => setNewUserPhone(e)}
                      />
                    )}
                    {signUpSetting.isSignUpBirthdayEnabled && (
                      <Grid container spacing={2}>
                        <fieldset className={classes.fieldsetWrapper}>
                          <Grid item xs={12} className={classes.dateFieldTitleWrapper}>
                            <legend>
                              <Typography className={classes.dateFieldTitle}>
                                {signUpSetting.isSignUpBirthdayRequired ? t('users.birthday') : t('users.birthday_optional')}
                              </Typography>
                            </legend>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={7}>
                              <SelectGroup
                                onSelect={e => e.preventDefault()}
                                aria-label={t('models.user.month')}
                                data-cy={'birthMonth'}
                                isClearable={!signUpSetting.isSignUpBirthdayRequired}
                                field="birthMonth"
                                options={monthDayOptions(t, values, 'birth').months}
                                title={t('models.user.month')}
                                validate={validateMonthDate(signUpSetting.isSignUpBirthdayRequired, 'birthMonth', basicFormApi)}
                                size="lg"
                                onMenuOpen={() => setIsMonthDropdownOpen(true)}
                                onMenuClose={() => setIsMonthDropdownOpen(false)}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <SelectGroup
                                aria-label={t('models.user.day')}
                                data-cy={'birthDay'}
                                isClearable={!signUpSetting.isSignUpBirthdayRequired}
                                field="birthDay"
                                options={monthDayOptions(t, values, 'birth').days}
                                title={t('models.user.day')}
                                validate={validateMonthDate(signUpSetting.isSignUpBirthdayRequired, 'birthDay', basicFormApi)}
                                size="lg"
                                onMenuOpen={() => setIsDayDropdownOpen(true)}
                                onMenuClose={() => setIsDayDropdownOpen(false)}
                              />
                            </Grid>
                          </Grid>
                        </fieldset>
                      </Grid>
                    )}
                    {signUpSetting.isSignUpAnniversaryEnabled && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.dateFieldTitleWrapper}>
                          <Box mt={1}>
                            <Typography className={classes.dateFieldTitle}>
                              {signUpSetting.isSignUpAnniversaryRequired ? t('users.anniversary') : t('users.anniversary_optional')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <SelectGroup
                            aria-label={t('models.user.month')}
                            data-cy={'anniversaryMonth'}
                            isClearable={!signUpSetting.isSignUpAnniversaryRequired}
                            field={'weddingMonth'}
                            options={monthDayOptions(t, values, 'anniversary').months}
                            title={t('models.user.month')}
                            size="lg"
                            validate={validateMonthDate(signUpSetting.isSignUpAnniversaryRequired, 'anniversaryMonth', basicFormApi)}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <SelectGroup
                            aria-label={t('models.user.day')}
                            data-cy={'anniversaryDay'}
                            isClearable={!signUpSetting.isSignUpAnniversaryRequired}
                            field={'weddingDay'}
                            options={monthDayOptions(t, values, 'anniversary').days}
                            title={t('models.user.day')}
                            validate={validateMonthDate(signUpSetting.isSignUpAnniversaryRequired, 'anniversaryDay', basicFormApi)}
                            size="lg"
                          />
                        </Grid>
                      </Grid>
                    )}
                    {enabledLocations.length > 1 && (
                      <Box marginTop={3}>
                        <SelectGroup
                          data-cy={'location'}
                          field="favoriteLocationId"
                          options={sortByKey(enabledLocations.map(option => ({
                            label: option.name,
                            value: option.id,
                          })), 'label')}
                          title={t('models.follower.favorite_location.name.required')}
                          validate={notBlankValidator}
                          onMenuOpen={() => {
                            const fieldName = 'favoriteLocationId';
                            const { setFieldMeta } = basicFormApi;
                            const value = values[fieldName];
                            if (!value) {
                              setFieldMeta(fieldName, { error: 'Favorite Location is a required field' });
                            }
                          }}
                        />
                      </Box>
                    )}
                    {/* NCR loyalty require phone number, so we must ask for marketing text opt-ins */}
                    {(isMarketingOptInEnabled || isNcrLoyaltyEnabled) && (
                      <MarketingOptInRequest
                        grayedText
                        isNcrLoyaltyEnabled={isNcrLoyaltyEnabled}
                        smsOptInDisabled={!values.phone}
                      />
                    )}

                    <FollowDisclaimer
                      grayedText
                    />

                    <SubmitGroup
                      block
                      className={classes.pmSignUpV2Btn}
                      ButtonProps={{ className: 'gtm-sign-up-button' }}
                      color="primary"
                      disabled={validateSubmit(values, signUpSetting, enabledLocations.length, isNcrLoyaltyEnabled)}
                      size="lg"
                      title="Sign Up"
                    />
                    <Button
                      className={classes.termsButton}
                      onClick={() => props.openTermsModal(TermsModalType.ClientTerms)}
                      variant="text"
                    >
                      <FormattedMessage className={classes.grayedText} id="follower.terms_of_service" defaultMessage="Popmenu Terms of Service" />
                    </Button>
                  </AHLevelProvider>
                </div>
              );
            }}
          </BasicForm>
        );
      }}
    </Query>
  );
};

SignUpV2Form.defaultProps = {
  userEmail: null,
};

SignUpV2Form.propTypes = {
  classes: PropTypes.object.isRequired,
  onExistingUser: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    customConversionScripts: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string,
      scriptType: PropTypes.string,
    })),
    googleAdsConversionActionScripts: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      tag: PropTypes.string,
    })),
    id: PropTypes.number,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    signUpBackgroundImageUrl: PropTypes.string,
    signUpButtonCta: PropTypes.string,
    signUpHeadingCta: PropTypes.string,
    signUpSubheadingCta: PropTypes.string,
  }).isRequired,
  setFavoriteLocationId: PropTypes.func.isRequired,
  setNewUserName: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
};

export default compose(
  connect(
    () => ({}),
    {
      openTermsModal,
    },
  ),
  withStyles(SignUpFormStyles),
  withIntl,
)(SignUpV2Form);
